/*css for GreenAcesLegalStatement.js */


.privacyPolicyScrollable { /*This div is scrollable because of overflow property*/

    height: 200px; /* set the height of the scrollable div */
    overflow: auto; /* or scroll */
    background-color: slategray;
    padding: 20px;
    min-height: calc(100vh - 50px);
  }


.background{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    
}

.MainText01{
    background-color: slategray;
    height: 800px; /* sets the height of the background color*/
    
    
    
}

.Header1, h2 {
 text-align: left;
}

.Header2, h2 {
 text-align: left;
}

.Header3, h2 {
text-align: left;   
}

.Header4, h2 {
text-align: left;  
}




/*start of viewport tag for mobile devices*/

@viewport {
    width: device-width;
    zoom: 1.0;
    }
    
