/*Settings for AboutGreenAces.js*/


/* For desktop: */



.AboutPageBody {
  box-sizing: border-box;
  background-color: #FFF;
  

}

.smallBoarderBackground{
text-align: center;
width: 30vw;
height: 60vh;
position: center;


}

.profileFrame {
background-color: #23252C;
width: 30vw;
height: 60vh;
position: center;
height: -webkit-fill-available;

}

.blackBorderTop {

background-color: #23252C;
width: 30vw;
height: 1.5vh;
position: center;

}

.insideBox {

position: absolute;
width: 30vw;
height: 60vh;
position: center;
height: -webkit-fill-available;
top: 40vh;
right: 30vw;

}



.displayPicture {

position: relative;
width: 250px;
height: 350px;


}

.greenacesText {

 color: #FFFFFF;
 text-align: center;
}

.frontendFont {

 background-color: #23252C; 
 color: #F3BF99;
 text-align: center;
 height: 20vh;
}

#GreenAcesLink{

color: #FFFFFF;
}


/*link color settings for greenaces link */


a:link {color: #F5F5F5;}

a:link {
color: #F5F5F5;
background-color: transparent;
text-decoration: none;
}

a:visited {
color: #F5F5F5;
background-color: transparent;
text-decoration: none;
}

a:hover {
color: #F5F5F5;
background-color: transparent;
text-decoration: underline;
}

a:active {
color: #F5F5F5;
background-color: transparent;
text-decoration: underline;
}


.ButtonWrapper {

margin: 0 auto;
width: 115px;
height: 34px;
position: relative;
left: -70px;
right: 0px;
bottom: 0px;
top: 0px;

}

/*link color settings for email button link */

.emailBtn a:link, a:visited {
color: black;
text-align: center;
text-decoration: none;
display: inline-block;

background: #FFFFFF;
/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;

}



.emailBtn {



color: black;

box-sizing: border-box;

/* white */

background: #FFFFFF;
/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;

/* Button */


box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 9px 13px 9px 11px;
gap: 8px;


width: 115px;
height: 34px;
position: relative;
left: 1vh;
right: 0px;
bottom: 0px;
top: 20px;
/* white */

background: #FFFFFF;
/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;




}


/*link color settings for linkin button link */

.linkinBtn a:link, a:visited {
text-align: center;
text-decoration: none;
display: inline-block;


/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;

}

.linkinBtn {

box-sizing: border-box;

/* white */

background: #FFFFFF;
/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;

/* Button */


/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 9px 13px 9px 11px;
gap: 8px;

width: 115px;
height: 34px;
position: relative;
left: 130px;
right: 0px;
bottom: 0px;
top: -14px;

background: #297FE5;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;


}



/*email and linkedin images */

.emailLogo {

max-width: 25%;
max-height: 1000%;
background: #FFFFFF;


}

.linkinLogo {

max-width: 25%;
max-height: 1000%;
background: #297FE5;


}

.primarySection {

background-color: #23252C;
width: 30vw;

}


.aboutMeSection {

   text-align: center;
   background-color: #23252C;
   width: 30vw;
   height: 29vh;
   

}

.AboutSectionContent {

text-align: center;


}

#AboutHeadline {

 background-color: #23252C;
 color: #F3BF99;
 text-align: center;

}



.paragraphOne {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12.24px;
  line-height: 150%;
  /* or 15px */
  color: #DCDCDC;
  background-color: #23252C;
  width: 21vw;

}

#paragraphOneAlignment {

 text-align: left;
 background-color: #23252C;
 font-family: 'Inter';
 font-style: normal;
 font-weight: 400;
 font-size: 12.24px;
 line-height: 150%;
 /* or 15px */
 color: #DCDCDC;
 background-color: #23252C;
 width: 30vw;
 margin: 0 auto; /* add this line to center the element */


}

.interestSection {

    
   background-color: #23252C;
   width: 30vw;
   

}

#AboutInterest {

background-color: #23252C;
color: #F3BF99;
text-align: center;


}


.paragraphTwo {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12.24px;
  line-height: 150%;
  /* or 15px */
  color: #DCDCDC;
  background-color: #23252C;
  width: 30vw;
  height: 60vh;
}

.paragraphTwoAlignment {

  text-align: left;
  background-color: #23252C;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12.24px;
  line-height: 150%;
  /* or 15px */
  color: #DCDCDC;
  background-color: #23252C;
  width: 30vw;
  margin: 0 auto; /* add this line to center the element */
 


}

.ParagraphTwoHighlights {

  text-align: left;
  background-color: #23252C;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15.24px;
  
  /* or 15px */
  color: #DCDCDC;
  background-color: #23252C;
  width: 30vw;
  margin: 0 auto; /* add this line to center the element */
  
 

}


#AB {


 background-color: #23252C;
 color: #F3BF99;
}

#ABC {
 
 
 background-color: #23252C;
 color: #F3BF99;
}


#ABCD {

 background-color: #23252C;
 color: #F3BF99;
}


#ABCDE {

 background-color: #23252C;
 color: #F3BF99;
}


#ABCDEF {

 background-color: #23252C;
 color: #F3BF99;
}








/*body section*/








/*footer section*/

.rectangle93 {

  position: bottom;
  width: 30vw;
  height: 10vh;
  left: 0px;
  top: 0px;
  background: #161619;
  border-radius: 0px 0px 10px 10px;
  background-color: #23252C;



}



.youtubeLogo {

  height: 25px;
  width: 25px;
  position: bottom;
  left: 18.82%;
  right: 56.64%;
  top: 89.8%;
  bottom: 7.32%;
  background: #161619;



}

.tiktokLogo {

  height: 25px;
  width: 25px;
  position: bottom;
  left: 47.73%;
  right: 47.73%;
  top: 89.8%;
  bottom: 7.32%;
  background: #161619;

}

.twitterLogo {

  height: 25px;
  width: 25px;
  position: bottom;
  left: 56.64%;
  right: 38.82%;
  top: 89.8%;
  bottom: 7.32%;
  background: #161619;

}

.githubLogo {

  height: 25px;
  width: 25px;
  position: bottom;
  left: 65.55%;
  right: 29.91%;
  top: 89.8%;
  bottom: 7.32%;
  background: #161619;

}







 /* For mobile phones: */
@media only screen and (max-width: 768px) {
 


.AboutPageBody {
  box-sizing: border-box;
  background-color: #FFF;
  

}

.smallBoarderBackground{
text-align: center;
width: 68vw;
height: 60vh;
position: center;


}

.profileFrame {
background-color: #23252C;
width: 68vw;
height: 60vh;
position: center;
height: -webkit-fill-available;

}

.blackBorderTop {

background-color: #23252C;
width: 68vw;
height: 1.5vh;
position: center;

}

.insideBox {

position: absolute;
width: 68vw;
height: 60vh;
position: center;
height: -webkit-fill-available;
top: 40vh;
right: 30vw;

}



.displayPicture {

position: relative;
width: 250px;
height: 350px;


}

.greenacesText {

 color: #FFFFFF;
 text-align: center;
}

.frontendFont {

 color: #F3BF99;
 text-align: center;

}



#GreenAcesLink{


color: #FFFFFF;
}


/*link color settings for greenaces link */


a:link {color: #F5F5F5;}

a:link {
color: #F5F5F5;
background-color: transparent;
text-decoration: none;
}

a:visited {
color: #F5F5F5;
background-color: transparent;
text-decoration: none;
}

a:hover {
color: #F5F5F5;
background-color: transparent;
text-decoration: underline;
}

a:active {
color: #F5F5F5;
background-color: transparent;
text-decoration: underline;
}


.ButtonWrapper {

margin: 0 auto;
width: 115px;
height: 34px;
position: relative;
left: -70px;
right: 0px;
bottom: 0px;
top: 0px;

}

/*link color settings for email button link */

.emailBtn a:link, a:visited {
color: black;
text-align: center;
text-decoration: none;
display: inline-block;

background: #FFFFFF;
/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;

}



.emailBtn {



color: black;

box-sizing: border-box;

/* white */

background: #FFFFFF;
/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;

/* Button */


box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 9px 13px 9px 11px;
gap: 8px;


width: 115px;
height: 34px;
position: relative;
left: 1vh;
right: 0px;
bottom: 0px;
top: 20px;
/* white */

background: #FFFFFF;
/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;




}


/*link color settings for linkin button link */

.linkinBtn a:link, a:visited {
text-align: center;
text-decoration: none;
display: inline-block;


/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;

}

.linkinBtn {

box-sizing: border-box;

/* white */

background: #FFFFFF;
/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;

/* Button */


/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 9px 13px 9px 11px;
gap: 8px;

width: 115px;
height: 34px;
position: relative;
left: 130px;
right: 0px;
bottom: 0px;
top: -14px;

background: #297FE5;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;


}



/*email and linkedin images */

.emailLogo {

max-width: 25%;
max-height: 1000%;
background: #FFFFFF;


}

.linkinLogo {

max-width: 25%;
max-height: 1000%;
background: #297FE5;


}

.primarySection {

background-color: #23252C;
width: 68vw;

}


.aboutMeSection {

   text-align: center;
   background-color: #23252C;
   width: 68vw;
   height: 29vh;
   

}

.AboutSectionContent {

text-align: center;


}





#AboutHeadline {

 background-color: #23252C;
 color: #F3BF99;

}



.paragraphOne {

  
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12.24px;
  line-height: 150%;
  /* or 15px */
  color: #DCDCDC;
  background-color: #23252C;
  width: 21vw;

}

#paragraphOneAlignment {


 background-color: #23252C;
 font-family: 'Inter';
 font-style: normal;
 font-weight: 400;
 font-size: 12.24px;
 line-height: 150%;
 /* or 15px */
 color: #DCDCDC;
 background-color: #23252C;
 width: 68vw;


}

.interestSection {

   
   background-color: #23252C;
   width: 68vw;

}

#AboutInterest {

background-color: #23252C;
color: #F3BF99;

}


.paragraphTwo {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12.24px;
  line-height: 150%;
  /* or 15px */
  color: #DCDCDC;
  background-color: #23252C;
  width: 68vw;
  height: 60vh;
}

.paragraphTwoAlignment {

 text-align: left;
 background-color: #23252C;
 font-family: 'Inter';
 font-style: normal;
 font-weight: 400;
 font-size: 12.24px;
 line-height: 150%;
 /* or 15px */
 color: #DCDCDC;
 background-color: #23252C;
 width: 68vw;
 height: 60vh;



}


.ParagraphTwoHighlights {

  text-align: left;
  background-color: #23252C;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15.24px;
  
  /* or 15px */
  color: #DCDCDC;
  background-color: #23252C;
  width: 30vw;
  margin: 0 auto; /* add this line to center the element */
  
 

}



#AB {


 background-color: #23252C;
 color: #F3BF99;
}

#ABC {
 
 
 background-color: #23252C;
 color: #F3BF99;
}


#ABCD {

 background-color: #23252C;
 color: #F3BF99;
}


#ABCDE {

 background-color: #23252C;
 color: #F3BF99;
}


#ABCDEF {

 background-color: #23252C;
 color: #F3BF99;
}








/*body section*/








/*footer section*/

.rectangle93 {

  position: bottom;
  width: 68vw;
  height: 10vh;
  left: 0px;
  top: 0px;
  background: #161619;
  border-radius: 0px 0px 10px 10px;
  background-color: #23252C;



}



.youtubeLogo {

  height: 25px;
  width: 25px;
  position: bottom;
  left: 18.82%;
  right: 56.64%;
  top: 89.8%;
  bottom: 7.32%;
  background: #161619;



}

.tiktokLogo {

  height: 25px;
  width: 25px;
  position: bottom;
  left: 47.73%;
  right: 47.73%;
  top: 89.8%;
  bottom: 7.32%;
  background: #161619;

}

.twitterLogo {

  height: 25px;
  width: 25px;
  position: bottom;
  left: 56.64%;
  right: 38.82%;
  top: 89.8%;
  bottom: 7.32%;
  background: #161619;

}

.githubLogo {

  height: 25px;
  width: 25px;
  position: bottom;
  left: 65.55%;
  right: 29.91%;
  top: 89.8%;
  bottom: 7.32%;
  background: #161619;

}





}





/* new code 02-14-2023*/

/* For desktop */
@media screen and (min-width: 768px) {
  .smallBoarderBackground {
    width: 50vw;
  }

  .profileFrame {
    width: 50vw;
  }

  .blackBorderTop {
    width: 50vw;
  }

  .blackBorder {
    width: 50vw;
  }

  .insideBox {
    width: 50vw;
    right: 25vw;
  }

  .primarySection {
    width: 50vw;
  }

  .aboutMeSection {
    width: 50vw;
  }

  .paragraphOne {
    width: 40vw;
  }

  .paragraphTwo {
    width: 50vw;
  }



  .footerSection {
    width: 50vw;
  }

  .rectangle93 {
    width: 50vw;
  }
}

/* For mobile */
@media screen and (max-width: 768px) {

  .frontendFont{
    height:22vh;
  }
  .smallBoarderBackground {
    width: 80vw;
  }

  .profileFrame {
    width: 80vw;
  }

  .blackBorderTop {
    width: 80vw;
  }

  .blackBorder {
    width: 80vw;
  }

  .insideBox {
    width: 80vw;
    right: 10vw;
  }

  .primarySection {
    width: 80vw;
  }

  .aboutMeSection {
    width: 80vw;
  }

  .paragraphOne {
    text-align: center;
    width: 68vw;
  }

 

  .paragraphTwo {
    text-align: center;
    width: 80vw;
  }


  .ParagraphTwoHighlights {

    position: absolute;
    top: 283%;
    left: 47%;
    transform: translateX(-50%);
    width: 60vw;
  
  }

  .footerSection {
    width: 100%; 
    max-width: 1200px;
    margin: 0 auto;
  }

  .rectangle93 {
    width: 80vw;
  }
}





/*make font larger on larger screens*/


/*Settings for AboutGreenAces.js*/

/* For desktop: */
@media only screen and (min-width: 769px) {
  .AboutPageBody {
    font-size: 16px;
  }

  .greenacesText,
  .frontendFont,
  #GreenAcesLink,
  .paragraphOne,
  #paragraphOneAlignment,
  .AboutSectionContent,
  #AboutHeadline,
  .paragraphTwo,
  .paragraphTwoAlignment,
  #AB,
  #ABC,
  #ABCD,
  #ABCDE,
  #ABCDEF {
    font-size: 20px;
  }
}

/* For mobile phones: */
@media only screen and (max-width: 768px) {
  .AboutPageBody {
    box-sizing: border-box;
    background-color: #FFF;
  }

  /* other existing styles */
}
