  /*Homepage that renders the games and app thumbnails */

/*Homepage that renders the games and app thumbnails */



.page-content {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.game-description {
  font-weight: 550;
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
}

.game-thumbnail {
  width: 200px;
  height: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px; /* Add padding to the bottom */

  
}

@media (max-width: 768px) {
  .game-thumbnail {
    width: 100%;
  }
}


@viewport {
  width: device-width;
  zoom: 1.0;
  }






  
  
  
  
  
  
  
  
  
  
  
  
  