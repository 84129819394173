.h3 {
    text-align: center;
    font-family: 'Lucida Sans';
    color: black;
}




.section {
    text-align: center;
    background-color: grey;
    height: 50vh;
    width: 100vw;
    position: center;
    height: -webkit-fill-available;

}



.logo {
    height: 50vh;
    width: 50vw;
    position: center;
    text-align: center;
    height: -webkit-fill-available;
    
}

.text {
    text-align: center;
    color: #50e177;
  

}




/*start of viewport tag for mobile devices*/

@viewport {
    width: device-width;
    zoom: 1.0;
    }

