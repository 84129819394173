.spacer{
  margin-top: 20px;

}


.form-container {
  background-color: #708090;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%; /* Change to desired width */
  margin: 0 auto; /* Add this line to center the form horizontally */
  border: 3px solid black; /* Add border to form container */
}

label {
  display: block;
  margin: 10px 0;
  font-weight: bold;
  color: black; /* Change label text color to black */
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%; /* Change input width to 10% */
  background-color: #50e177; /* Change input background color to green */
  color: black; /* Change input text color to black */
  border: none;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

input[type="text"],
input[type="email"] {
  max-width: 100%;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  outline: none;
}

.form-container-textarea {
  background-color: #50e177;
  height: 100px;
  resize: none;
  
}

button {
  font-weight: bold;
  background-color: #50e177;
  color: black;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  margin: 10px auto;
  display: block;
  cursor: pointer;
  font-size: 1rem;
}

.responseColorMessage {

  text-align: center;
  color: #50e177;

}


  /*start of viewport tag for mobile devices*/

  @viewport {
    width: device-width;
    zoom: 1.0;
    }
    

  /*for desktops or notebooks or larger screen sizes*/

  @media (min-width: 768px) {
    .form-container {
      width: 85%; /* Adjust width for larger screens */
    }
  
    .form-container label,
    .form-container input,
    .form-container textarea {
      width: 100%; /* Make inputs wider */
      max-width: 100%; /* Make inputs responsive */
    }
  
    .form-container label {
      display: inline-block; /* Adjust label spacing */
      margin-right: 20px;
    }
  }
  
