body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}




.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


/*Navbar settings for greenaces*/

.dropdown-menu{

    background-color: #50e177;
    font-family: Helvetica;
    font-weight: 300;
    
    
}

/*GreenAces logo here*/

#GreenAcesNavbarBrand {

    height: 50px;
    width: 75px;
}

        


#label-row-browser-games {
    background-color: #708090;
}

#label-row-Apps {
    background-color: #708090;
}

#label-row-Stuff {
    background-color: #708090;
}

.dropdown-menu :hover{

    background-color: #D3D3D3

}

#navbarDropdownProjects {
    color: #50e177;
}

#label-column-lucky {
    color: black;
    font-size: larger;
    
}

#label-column-pokemon {
    color: black;
    font-size: larger;
}



#navbarDropdownAndroid {
    color: #50e177;
}

#label-column-App1 {
    color: black;
    font-size: larger;
}

#label-column-App2 {
    color: black;
    font-size: larger;
}

#navbarDropdownOther {
    color: #50e177;
}

#label-column-Test1 {
    color: black;
    font-size: larger;

}

#label-column-Test2 {
    color: black;
    font-size: larger;

}

#label-column-Test3 {
    color: black;
    font-size: larger;

}




/*for mobile devices*/

    /*This is the hamburger icon that displays options */
  @media (max-width: 480px) {
    .navbar-toggler {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  
/*====================
  Footer
====================== */


body, html {
  height: 100%;
  margin: 0;
}

.main-content {
  min-height: 100%;
}



.footer-1 {
  
    margin-top: auto;
    width: 100%;
    height: 40px;
    padding-top: 10px;
    position: absolute;
    bottom: 0;
    left: 0;
    
  }
  
  /* Main Footer */
  footer .main-footer{  padding: 20px 0;  background: #212529;}
  footer ul{  padding-left: 0;  list-style: none;}
  
  /* Copy Right Footer */
  .footer-copyright { background: #222; padding: 5px 0;}
  .footer-copyright .logo {    display: inherit;}
  .footer-copyright nav {    float: right;    margin-top: 5px;}
  .footer-copyright nav ul {  list-style: none; margin: 0;  padding: 0;}
  .footer-copyright nav ul li { border-left: 1px solid #505050; display: inline-block;  line-height: 12px;  margin: 0;  padding: 0 8px;}
  .footer-copyright nav ul li a{  color: #969696;}
  .footer-copyright nav ul li:first-child { border: medium none;  padding-left: 0;}
  .footer-copyright p { color: #969696; margin: 2px 0 0;}
  
  /* Footer Top */
  .footer-top{  background: #252525;  padding-bottom: 30px; margin-bottom: 30px;  border-bottom: 3px solid #222;}
  
  /* Footer transparent */
  footer.transparent .footer-top, footer.transparent .main-footer{  background: transparent;}
  footer.transparent .footer-copyright{ background: none repeat scroll 0 0 rgba(0, 0, 0, 0.3) ;}
  
  /* Footer light */
  footer.light .footer-top{ background: #f9f9f9;}
  footer.light .main-footer{  background: #f9f9f9;}
  footer.light .footer-copyright{ background: none repeat scroll 0 0 rgba(255, 255, 255, 0.3) ;}
  
  /* Footer 4 */
  .footer- .logo {    display: inline-block;}
  
  /*====================
    Widgets
  ====================== */
  .widget{  padding: 20px;  margin-bottom: 40px;}
  .widget.widget-last{  margin-bottom: 0px;}
  .widget.no-box{ padding: 0; background-color: transparent;  margin-bottom: 40px;
    box-shadow: none; -webkit-box-shadow: none; -moz-box-shadow: none; -ms-box-shadow: none; -o-box-shadow: none;}
  .widget.subscribe p{  margin-bottom: 18px;}
  .widget li a{ color: #50e177;}
  .widget li a:hover{ color: #ff8d1e;}
  .widget-title  {margin-bottom: 20px;}
  .widget-title span {background: #839FAD none repeat scroll 0 0;display: block; height: 1px;margin-top: 25px;position: relative;width: 20%;}
  .widget-title span::after {background: inherit;content: "";height: inherit;    position: absolute;top: -4px;width: 50%;}
  .widget-title.text-center span,.widget-title.text-center span::after {margin-left: auto;margin-right:auto;left: 0;right: 0;}
  .widget .badge{ float: right; background: #7f7f7f;}

  /*Custom button settings for signup*/

  .widget .signupNewsButton {
    color: #50e177;
    border-radius: 12px;
    color: white;
    padding: 0px 0px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    
  }

  .myButtonNews {

    background-color: #50e177; 
    border-radius: 12px;
    color: white;
    padding: 10px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    

  }

  .myButtonNews {
    transition-duration: 0.4s;
  }
  
  .myButtonNews:hover {
    background-color: #ff8d1e; 
    padding: 10px 24px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 15px;
    color: white;
  }
  

  /*Custom Email settings for GreenAces*/

  .GreenAcesMailTag .greenAcesEmailContact {color: #50e177;}

 

  
  .typo-light h1,
  .typo-light h2,
  .typo-light h3,
  .typo-light h4,
  .typo-light h5,
  .typo-light h6,
  .typo-light p,
  .typo-light div,
  .typo-light span,
  .typo-light small{  color: #fff;}
  
  ul.social-footer2 { margin: 0;padding: 0; width: auto;}
  ul.social-footer2 li {display: inline-block;padding: 0;}
  ul.social-footer2 li a:hover {background-color:#ff8d1e;}
  ul.social-footer2 li a {display: block; height:30px;width: 30px;text-align: center;}
  .btn{background-color: #ff8d1e; color:#fff;}
  .btn:hover, .btn:focus, .btn.active {background: #4b92dc;color: #fff;
  -ms-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  -o-box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.1);
  transition: all 250ms ease-in-out 0s;






  }
  
  
  
  /*change viewport settings here for desktop monitor*/

 
  
  @media (max-width: 1200px)  {
    
    body, html {
      height: 100%;
      margin: 0;
    }
    
    .main-content {
      min-height: 100%;
    }
    
    .footer {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 50px;
      background-color: #333;
      color: #fff;
      text-align: center;
    }
    
    
  
  }
  

 
  


  
 
  
 
  /*Homepage that renders the games and app thumbnails */

/*Homepage that renders the games and app thumbnails */



.page-content {
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.game-description {
  font-weight: 550;
  text-align: left;
  width: 100%;
  margin-bottom: 10px;
}

.game-thumbnail {
  width: 200px;
  height: 200px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px; /* Add padding to the bottom */

  
}

@media (max-width: 768px) {
  .game-thumbnail {
    width: 100%;
  }
}


@viewport {
  width: device-width;
  zoom: 1.0;
  }






  
  
  
  
  
  
  
  
  
  
  
  
  
/* NOTE: This is the external css file for index2.html and it is controlled by lucky7.js (javascript file)
          created 9/27/2018*/


/*custom bootstrap 5 navbar settings*/


.navbarDropdownProjects {
color: #50e177;
background-color: #212529;
}

 .navbarDropdownAndroid {
color: #50e177;
 background-color: #212529;
}

 .navbarDropdownOther {
 color: #50e177;
 background-color: #212529;
 }

 html,
 body {
   height: 100%;
 }

 .blackWallImage {

  background-image: url(/static/media/Black-wallpapers-33.b198561c.jpg);
  background-size: cover;
  min-height: 100%;

 }



 .formIconEdit {

  height: 35px;
  width:  35px;

}


.enjoyable_Yes_label,
.enjoyable_No_label,
.enjoyable_alright_label,
.age1_label,
.age2_label,
.age3_label,
.errors_Yes_label,
.errors_No_label,
.bugImpactGameplay_Yes_label,
.bugImpactGameplay_No_label,
.recommenedGame_Yes_label,
.recommenedGame_No_label,
.andriod_Yes_label,
.andriod_No_label,
.rateGame_1_label,
.rateGame_2_label,
.rateGame_3_label,
.rateGame_4_label,
.rateGame_5_label {
font-weight: normal;
}

               


/*table properties */


/*table 1 properties */
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th, td {
  padding: 15px;
  text-align: left;
}

/*table 1 properties */

.t01 {

  width: 11%;
  background-color: #00FFFF;
  
}

.table-status-displayRounds {
  text-align: center;
  font-weight: bold;
}

.table-rounds-type {

  font-weight: bold;
}




.h1 {
  color: white;
  text-align: center;
  font-size: 20px
}

.p {
  font-family: times;
  font-size: 20px;
  color: white;
}

/*font settings for lucky 7 game */

.displayGameAlert {

color: red;
font-weight: bold;
}

.InformPlayerLoss {

color: white;
font-weight: bold;

}

.InformPlayerWon {

color: green;
font-weight: bold;
font-size: 22px

}

.InformPlayerWonDetails {
 color: white;
 font-weight: bold;
}


.sendLink a:hover, a:active {
  
  color: blue;
  text-decoration: underline;
  
}



.logo1 {

display: block;
margin-left: auto;
margin-right: auto;

width: 400px;
height: 71px;

}




.GameInfo {

text-align: left;
color: white;

}


.GameButtonAndText {

text-align: center;

}

.diceBoxOne {

  justify-content: center;
  align-content: center;
  grid-auto-flow: column;
  display: flex;
  position: relative;
  left: 40%;
  top: 40%;
  -webkit-transform: translate(-20%, -9400%);
          transform: translate(-20%, -9400%);
  max-height: 2px;
  max-width: 2px;

 

}

.diceBoxTwo {

  justify-content: center;
  align-content: center;
  grid-auto-flow: column;
  display: flex;
  position: relative;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-500%, -9400%);
          transform: translate(-500%, -9400%);
  max-height: 2px;
  max-width: 2px;
  
 

}




.GameNumberFont {

  display: block;
  margin-left: auto;
  margin-right: auto;



}

.displayRandomNumber {

color: green;
font-weight: bold;
text-align: center;
font-size: 90px

}



.PlayAgainButton img {

display: block;
margin: 0 auto;
width: 160px;
height: 77px;

}


.dice1 img {

display: block;
margin: 0 auto;
width: 95px;
height: 95px;

}

.dice2 img {

display: block;
margin: 0 auto;
width: 95px;
height: 95px;

}



  /*change viewport settings here*/






/*Navbar settings for greenaces*/

.dropdown-menu{

  background-color: #50e177;
  font-family: Helvetica;
  font-weight: 300;
  
  
}

/*GreenAces logo here*/

#GreenAcesNavbarBrand {

  height: 50px;
  width: 75px;
}

      


#label-row-browser-games {
  background-color: #708090;
}

#label-row-Apps {
  background-color: #708090;
}

#label-row-Stuff {
  background-color: #708090;
}

.dropdown-menu :hover{

  background-color: #D3D3D3

}

#navbarDropdownProjects {
  color: #50e177;
}

#label-column-lucky {
  color: black;
  font-size: larger;
  
}

#label-column-pokemon {
  color: black;
  font-size: larger;
}



#navbarDropdownAndroid {
  color: #50e177;
}

#label-column-App1 {
  color: black;
  font-size: larger;
}

#label-column-App2 {
  color: black;
  font-size: larger;
}

#navbarDropdownOther {
  color: #50e177;
}

#label-column-Test1 {
  color: black;
  font-size: larger;

}

#label-column-Test2 {
  color: black;
  font-size: larger;

}

#label-column-Test3 {
  color: black;
  font-size: larger;

}



/*
update progress bar style here

*/

         /* progress bar for player1 HP*/

         .player1HP-progressBarBackground{
           background: black;
           padding: 3px;
           width: 100px;
         }

          .player1HP {
            width:0px;height:10px;
            background-color: #A6EDED;

           }


       /* speedbar for player1 */
          .playerSpeed-progressBarBackground{
          background: black;
          padding: 3px;
          width: 100px;
          }

          .playerSpeed {
              width:0px;height:10px;
              background-color: #A6EDED;

            }




            /* progress bar for computer HP*/

            .cpuHP-progressBarBackground{
              background: black;
              padding: 3px;
              width: 100px;
              }

            .cpuHP {
                width:0px;height:10px;
                background-color: #A6EDED;

              }


          /* speedbar for computer */

            .cpuSpeed-progressBarBackground{
              background: black;
              padding: 3px;
              width: 100px;
              }


              .cpuSpeed {
                  width:0px;height:10px;
                  background-color: #A6EDED;

                }




  /* player1 menus  */


#PokemonAttackMenuController{
   width: 100%;
}

.Player1Status {

  width: 100px;
  height: 150px;
  max-width:450px

}


.Player1PokemonContainer{
  width: 100px;
  height: 150px;
  max-width:450px

}

#Player1PokeImage img {
  display: block;
  width: 192px;
  height: 192px;

}

.AttackGraphicContainer{
  width: 80px;
  height: 80px;
  max-width:250px

}


#player1AttackImage img {
  display: block;
  width: 80px;
  height: 80px;
  max-width:250px


}




  /* computer menus */

#computerAttackImage img {
  display: block;
  width: 80px;
  height: 80px;
  max-width:250px


}



.CpuPokemonContainer{
  width: 100px;
  height: 150px;
  max-width:450px

}



#CpuPokeImage img {
  display: block;
  width: 188px;
  height: 188px;


}


.CpuStatus {

  width: 100px;
  height: 150px;
  max-width:450px
}



/*table for cpu icon pokemon*/

#tablePokemonIconIndicators2, tr, td {

  
  border: none;


}

/*table for player1 icon pokemon*/

#tablePokemonIconIndicators, tr, td {

  
  border: none;


}






/*pokemon_icon-indicators*/

/*computer pokemon icon */

#squirtleIcon {
  width: 34px;
  height: 46px;

}

#ScytherIcon {
  width: 34px;
  height: 46px;
}

#OnixIcon {
  width: 34px;
  height: 46px;

}



/*player1 pokemon icon */

#charmanderIcon {
  width: 34px;
  height: 46px;

}

#PikachuIcon {
  width: 34px;
  height: 46px;
}

#BlastoiseIcon {
  width: 34px;
  height: 46px;

}










/* main navbar sub-menu options */



/*w3 navbar*/

.dropbtn {
  background-color: #FFFFFF;
  color: white;
  padding: 16px;
  font-size: 16px;
  border: none;
}

.dropup {
  position: relative;
  display: inline-block;

}

.dropup-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  background-color : rgba(184, 229, 250, 0.5);
  min-width: 135px;
  bottom: 64px;
  z-index: 1;
  font-weight: 700;
  font-family: sans-serif;
  font-size: auto;

}

#dropUpPokemonSelectionMenu {
  background-color : rgba(184, 229, 250, 3);
}

#Charmander_sel {
  color: #70B663;
}

#Pikachu_sel {
  color: #70B663;
}

#Blastoise_sel {
  color: #70B663;
}




#dropupContentSettings {
  min-width: auto;
  font-weight: 700;
  font-family: sans-serif;
  font-size: auto;
}

.dropup-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropup-content a:hover {background-color: #A9E2F3}

.dropup:hover .dropup-content {
  display: block;
}

.dropup:hover .dropbtn {
  background-color: #A9E2F3;
}


/*google-navbar*/


body {
    margin: 0 0 55px 0;
}

.nav {

    align-self: center;
    position: fixed;
    bottom: 0px;
    width: 375px;
    height: 65px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

.nav__link {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    font-family: sans-serif;
    font-size: 13px;
    color: #444444;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;


}

.nav__link:hover {
    background-color: #eeeeee;
}

.nav__link--active {
    color: #009578;
}

.nav__icon {
    font-size: 18px;
}




/* pokemon text indicators AND statusLayoutProgress settings */

.statusLayoutProgress{
  width: 200px;
  height: 77px;

/*
  statusLayoutProgress is the div tag that holds the text indicators
  max height should not exceed 98px for statusLayoutProgress
  and max width should not exceed 200px for statusLayoutProgress
*/


}

#statusProgress {

  font-size: 10px;

}

#statusProgress2 {

  font-size: 10px;

}


#statusProgress3 {

font-size: 10px;


}






/* main navbar menu options */

#attack-menu-icon{
  width: 58px;
  height: 30px;
  display:inline-block;

}

#defense-menu-icon{
  width: 58px;
  height: 30px;
  display:inline-block;

}

#select-menu-icon{
  width: 58px;
  height: 30px;
  display:inline-block;

}

#settings-menu-icon{
  width: 58px;
  height: 30px;
  display:inline-block;

}

#hamburger-menu-icon{
  width: 58px;
  height: 30px;
  display:inline-block;

}


/*text above toggle button*/

.LightMode-text {
  text-align: center;
  color: black;

}

/*text above toggle button*/

button#soundOnText {
  background-color: transparent;
  color: black; /* this is not being applied for some weird reason */
  border: none;
}





/* Toggle buttons for sound and dark theme*/

#darkModeToggle + .slider {
  background-color: white;
}

#darkModeToggle:checked + .slider {
  background-color: slategray;
  
}

#darkModeToggle:checked + .slider:before {
  -webkit-transform: translateX(26px);
          transform: translateX(26px);
}

#soundToggle + .slider {
  background-color: white;
}

#soundToggle:checked + .slider {
  background-color: slategray;
}

#soundToggle:checked + .slider:before {
  -webkit-transform: translateX(26px);
          transform: translateX(26px);
}

.switch {
  position: relative;
  display: block;
  width: 60px;
  height: 34px;
  margin: 0 auto;
}

input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: gray;
  transition: .4s;
  border-radius: 50%;
}

input[type="checkbox"]:focus + .slider {
  box-shadow: 0 0 1px slategray;
  
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}


/*part 2 for slider setting related to dark/light mode*/

.theme-toggler.light {
  background-color: white;
}

.theme-toggler.dark {
  background-color: grey;
}


/*restartButton Image*/

.restart-text-button {
  text-align: center;
  color: black;

}

#theRestartButton {

  display: block;
  height: 42px;
  width: 32px;
  margin-left: auto;
  margin-right: auto;

}





/*Custom settings here for mobile devices*/


@media only screen and (max-width: 767px)  {

  

  html, .body {
  overflow-x: hidden;
  
  }


  #squirtleIcon, #ScytherIcon, #OnixIcon, #charmanderIcon, 
  #PikachuIcon, #BlastoiseIcon {
    margin-left: -20px;

  }




/* Toggle buttons */
#darkModeToggle + .slider {
  background-color: white;
}

#darkModeToggle:checked + .slider {
  background-color: slategray;
}

#darkModeToggle:checked + .slider:before {
  -webkit-transform: translateX(26px);
          transform: translateX(26px);
}

#soundToggle + .slider {
  background-color: white;
}

#soundToggle:checked + .slider {
  background-color: slategray;
}

#soundToggle:checked + .slider:before {
  -webkit-transform: translateX(26px);
          transform: translateX(26px);
}

.switch {
  position: relative;
  display: block;
  width: 60px;
  height: 34px;
  margin: 0 auto;
}

input[type="checkbox"] {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: gray;
  transition: .4s;
  border-radius: 50%;
}

input[type="checkbox"]:focus + .slider {
  box-shadow: 0 0 1px slategray;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}




  
/*part 2 for slider setting related to dark/light mode*/

.theme-toggler.light {
  background-color: white;
}

.theme-toggler.dark {
  background-color: grey;
}


  } /*end of media tag -- do not code below this line*/










 
.PrivacyPolicy_privacyPolicyScrollable__2FpN5 { /*This div is scrollable because of overflow property*/

    height: 200px; /* set the height of the scrollable div */
    overflow: auto; /* or scroll */
    background-color: slategray;
    padding: 20px;
    min-height: calc(100vh - 50px);
  }
  
  .PrivacyPolicy_policySectionTitle__29zTE {
    margin-top: 20px;
    text-align: left;
  }
  
  .PrivacyPolicy_policyEffectiveDate__1jtNP {
    margin-top: 40px;
  }
  

  

 
  /*start of viewport tag for mobile devices*/

@viewport {
    width: device-width;
    zoom: 1.0;
    }
    
/*css for GreenAcesLegalStatement.js */


.LegalStatement_privacyPolicyScrollable__1LnqT { /*This div is scrollable because of overflow property*/

    height: 200px; /* set the height of the scrollable div */
    overflow: auto; /* or scroll */
    background-color: slategray;
    padding: 20px;
    min-height: calc(100vh - 50px);
  }


.LegalStatement_background__worSN{
    margin: 0 auto;
    display: flex;
    justify-content: center;
    
}

.LegalStatement_MainText01__38kiO{
    background-color: slategray;
    height: 800px; /* sets the height of the background color*/
    
    
    
}

.LegalStatement_Header1__3Hg9h, h2 {
 text-align: left;
}

.LegalStatement_Header2__3Z_Rw, h2 {
 text-align: left;
}

.LegalStatement_Header3__1tSUU, h2 {
text-align: left;   
}

.LegalStatement_Header4__3bc6A, h2 {
text-align: left;  
}




/*start of viewport tag for mobile devices*/

@viewport {
    width: device-width;
    zoom: 1.0;
    }
    

/*Settings for AboutGreenAces.js*/


/* For desktop: */



.AboutGreenAces_AboutPageBody__2dkvX {
  box-sizing: border-box;
  background-color: #FFF;
  

}

.AboutGreenAces_smallBoarderBackground__1-dg8{
text-align: center;
width: 30vw;
height: 60vh;
position: center;


}

.AboutGreenAces_profileFrame__1Lllj {
background-color: #23252C;
width: 30vw;
height: 60vh;
position: center;
height: -webkit-fill-available;

}

.AboutGreenAces_blackBorderTop__2Pxtu {

background-color: #23252C;
width: 30vw;
height: 1.5vh;
position: center;

}

.AboutGreenAces_insideBox__3uMla {

position: absolute;
width: 30vw;
height: 60vh;
position: center;
height: -webkit-fill-available;
top: 40vh;
right: 30vw;

}



.AboutGreenAces_displayPicture__iYVCj {

position: relative;
width: 250px;
height: 350px;


}

.AboutGreenAces_greenacesText__O5BLw {

 color: #FFFFFF;
 text-align: center;
}

.AboutGreenAces_frontendFont__3j6em {

 background-color: #23252C; 
 color: #F3BF99;
 text-align: center;
 height: 20vh;
}

#AboutGreenAces_GreenAcesLink__1qpn5{

color: #FFFFFF;
}


/*link color settings for greenaces link */


a:link {color: #F5F5F5;}

a:link {
color: #F5F5F5;
background-color: transparent;
text-decoration: none;
}

a:visited {
color: #F5F5F5;
background-color: transparent;
text-decoration: none;
}

a:hover {
color: #F5F5F5;
background-color: transparent;
text-decoration: underline;
}

a:active {
color: #F5F5F5;
background-color: transparent;
text-decoration: underline;
}


.AboutGreenAces_ButtonWrapper__2cSgR {

margin: 0 auto;
width: 115px;
height: 34px;
position: relative;
left: -70px;
right: 0px;
bottom: 0px;
top: 0px;

}

/*link color settings for email button link */

.AboutGreenAces_emailBtn__3aIUT a:link, a:visited {
color: black;
text-align: center;
text-decoration: none;
display: inline-block;

background: #FFFFFF;
/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;

}



.AboutGreenAces_emailBtn__3aIUT {



color: black;

box-sizing: border-box;

/* white */

background: #FFFFFF;
/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;

/* Button */


box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 9px 13px 9px 11px;
grid-gap: 8px;
gap: 8px;


width: 115px;
height: 34px;
position: relative;
left: 1vh;
right: 0px;
bottom: 0px;
top: 20px;
/* white */

background: #FFFFFF;
/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;




}


/*link color settings for linkin button link */

.AboutGreenAces_linkinBtn__2g7Mv a:link, a:visited {
text-align: center;
text-decoration: none;
display: inline-block;


/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;

}

.AboutGreenAces_linkinBtn__2g7Mv {

box-sizing: border-box;

/* white */

background: #FFFFFF;
/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;

/* Button */


/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 9px 13px 9px 11px;
grid-gap: 8px;
gap: 8px;

width: 115px;
height: 34px;
position: relative;
left: 130px;
right: 0px;
bottom: 0px;
top: -14px;

background: #297FE5;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;


}



/*email and linkedin images */

.AboutGreenAces_emailLogo__3xc7B {

max-width: 25%;
max-height: 1000%;
background: #FFFFFF;


}

.AboutGreenAces_linkinLogo__j1aMS {

max-width: 25%;
max-height: 1000%;
background: #297FE5;


}

.AboutGreenAces_primarySection__3SvOs {

background-color: #23252C;
width: 30vw;

}


.AboutGreenAces_aboutMeSection__2xoTm {

   text-align: center;
   background-color: #23252C;
   width: 30vw;
   height: 29vh;
   

}

.AboutGreenAces_AboutSectionContent__1hM7a {

text-align: center;


}

#AboutGreenAces_AboutHeadline__dMLZj {

 background-color: #23252C;
 color: #F3BF99;
 text-align: center;

}



.AboutGreenAces_paragraphOne__33RKk {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12.24px;
  line-height: 150%;
  /* or 15px */
  color: #DCDCDC;
  background-color: #23252C;
  width: 21vw;

}

#AboutGreenAces_paragraphOneAlignment__13o6n {

 text-align: left;
 background-color: #23252C;
 font-family: 'Inter';
 font-style: normal;
 font-weight: 400;
 font-size: 12.24px;
 line-height: 150%;
 /* or 15px */
 color: #DCDCDC;
 background-color: #23252C;
 width: 30vw;
 margin: 0 auto; /* add this line to center the element */


}

.AboutGreenAces_interestSection__6sJ0T {

    
   background-color: #23252C;
   width: 30vw;
   

}

#AboutGreenAces_AboutInterest__1nEJX {

background-color: #23252C;
color: #F3BF99;
text-align: center;


}


.AboutGreenAces_paragraphTwo__1fI6U {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12.24px;
  line-height: 150%;
  /* or 15px */
  color: #DCDCDC;
  background-color: #23252C;
  width: 30vw;
  height: 60vh;
}

.AboutGreenAces_paragraphTwoAlignment__1Zmri {

  text-align: left;
  background-color: #23252C;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12.24px;
  line-height: 150%;
  /* or 15px */
  color: #DCDCDC;
  background-color: #23252C;
  width: 30vw;
  margin: 0 auto; /* add this line to center the element */
 


}

.AboutGreenAces_ParagraphTwoHighlights__PMFcq {

  text-align: left;
  background-color: #23252C;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15.24px;
  
  /* or 15px */
  color: #DCDCDC;
  background-color: #23252C;
  width: 30vw;
  margin: 0 auto; /* add this line to center the element */
  
 

}


#AboutGreenAces_AB__3NcLm {


 background-color: #23252C;
 color: #F3BF99;
}

#AboutGreenAces_ABC__1HoPX {
 
 
 background-color: #23252C;
 color: #F3BF99;
}


#AboutGreenAces_ABCD__2MoIa {

 background-color: #23252C;
 color: #F3BF99;
}


#AboutGreenAces_ABCDE__3I-qL {

 background-color: #23252C;
 color: #F3BF99;
}


#AboutGreenAces_ABCDEF__h63fb {

 background-color: #23252C;
 color: #F3BF99;
}








/*body section*/








/*footer section*/

.AboutGreenAces_rectangle93__2aq5I {

  position: bottom;
  width: 30vw;
  height: 10vh;
  left: 0px;
  top: 0px;
  background: #161619;
  border-radius: 0px 0px 10px 10px;
  background-color: #23252C;



}



.AboutGreenAces_youtubeLogo__1z8_J {

  height: 25px;
  width: 25px;
  position: bottom;
  left: 18.82%;
  right: 56.64%;
  top: 89.8%;
  bottom: 7.32%;
  background: #161619;



}

.AboutGreenAces_tiktokLogo__1DSFJ {

  height: 25px;
  width: 25px;
  position: bottom;
  left: 47.73%;
  right: 47.73%;
  top: 89.8%;
  bottom: 7.32%;
  background: #161619;

}

.AboutGreenAces_twitterLogo__15RUc {

  height: 25px;
  width: 25px;
  position: bottom;
  left: 56.64%;
  right: 38.82%;
  top: 89.8%;
  bottom: 7.32%;
  background: #161619;

}

.AboutGreenAces_githubLogo__3C8Bl {

  height: 25px;
  width: 25px;
  position: bottom;
  left: 65.55%;
  right: 29.91%;
  top: 89.8%;
  bottom: 7.32%;
  background: #161619;

}







 /* For mobile phones: */
@media only screen and (max-width: 768px) {
 


.AboutGreenAces_AboutPageBody__2dkvX {
  box-sizing: border-box;
  background-color: #FFF;
  

}

.AboutGreenAces_smallBoarderBackground__1-dg8{
text-align: center;
width: 68vw;
height: 60vh;
position: center;


}

.AboutGreenAces_profileFrame__1Lllj {
background-color: #23252C;
width: 68vw;
height: 60vh;
position: center;
height: -webkit-fill-available;

}

.AboutGreenAces_blackBorderTop__2Pxtu {

background-color: #23252C;
width: 68vw;
height: 1.5vh;
position: center;

}

.AboutGreenAces_insideBox__3uMla {

position: absolute;
width: 68vw;
height: 60vh;
position: center;
height: -webkit-fill-available;
top: 40vh;
right: 30vw;

}



.AboutGreenAces_displayPicture__iYVCj {

position: relative;
width: 250px;
height: 350px;


}

.AboutGreenAces_greenacesText__O5BLw {

 color: #FFFFFF;
 text-align: center;
}

.AboutGreenAces_frontendFont__3j6em {

 color: #F3BF99;
 text-align: center;

}



#AboutGreenAces_GreenAcesLink__1qpn5{


color: #FFFFFF;
}


/*link color settings for greenaces link */


a:link {color: #F5F5F5;}

a:link {
color: #F5F5F5;
background-color: transparent;
text-decoration: none;
}

a:visited {
color: #F5F5F5;
background-color: transparent;
text-decoration: none;
}

a:hover {
color: #F5F5F5;
background-color: transparent;
text-decoration: underline;
}

a:active {
color: #F5F5F5;
background-color: transparent;
text-decoration: underline;
}


.AboutGreenAces_ButtonWrapper__2cSgR {

margin: 0 auto;
width: 115px;
height: 34px;
position: relative;
left: -70px;
right: 0px;
bottom: 0px;
top: 0px;

}

/*link color settings for email button link */

.AboutGreenAces_emailBtn__3aIUT a:link, a:visited {
color: black;
text-align: center;
text-decoration: none;
display: inline-block;

background: #FFFFFF;
/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;

}



.AboutGreenAces_emailBtn__3aIUT {



color: black;

box-sizing: border-box;

/* white */

background: #FFFFFF;
/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;

/* Button */


box-sizing: border-box;

/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 9px 13px 9px 11px;
grid-gap: 8px;
gap: 8px;


width: 115px;
height: 34px;
position: relative;
left: 1vh;
right: 0px;
bottom: 0px;
top: 20px;
/* white */

background: #FFFFFF;
/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;




}


/*link color settings for linkin button link */

.AboutGreenAces_linkinBtn__2g7Mv a:link, a:visited {
text-align: center;
text-decoration: none;
display: inline-block;


/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;

}

.AboutGreenAces_linkinBtn__2g7Mv {

box-sizing: border-box;

/* white */

background: #FFFFFF;
/* gray/300 */

border: 1px solid #D1D5DB;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;

/* Button */


/* Auto layout */

display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 9px 13px 9px 11px;
grid-gap: 8px;
gap: 8px;

width: 115px;
height: 34px;
position: relative;
left: 130px;
right: 0px;
bottom: 0px;
top: -14px;

background: #297FE5;
/* shadow/sm */

box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
border-radius: 6px;


}



/*email and linkedin images */

.AboutGreenAces_emailLogo__3xc7B {

max-width: 25%;
max-height: 1000%;
background: #FFFFFF;


}

.AboutGreenAces_linkinLogo__j1aMS {

max-width: 25%;
max-height: 1000%;
background: #297FE5;


}

.AboutGreenAces_primarySection__3SvOs {

background-color: #23252C;
width: 68vw;

}


.AboutGreenAces_aboutMeSection__2xoTm {

   text-align: center;
   background-color: #23252C;
   width: 68vw;
   height: 29vh;
   

}

.AboutGreenAces_AboutSectionContent__1hM7a {

text-align: center;


}





#AboutGreenAces_AboutHeadline__dMLZj {

 background-color: #23252C;
 color: #F3BF99;

}



.AboutGreenAces_paragraphOne__33RKk {

  
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12.24px;
  line-height: 150%;
  /* or 15px */
  color: #DCDCDC;
  background-color: #23252C;
  width: 21vw;

}

#AboutGreenAces_paragraphOneAlignment__13o6n {


 background-color: #23252C;
 font-family: 'Inter';
 font-style: normal;
 font-weight: 400;
 font-size: 12.24px;
 line-height: 150%;
 /* or 15px */
 color: #DCDCDC;
 background-color: #23252C;
 width: 68vw;


}

.AboutGreenAces_interestSection__6sJ0T {

   
   background-color: #23252C;
   width: 68vw;

}

#AboutGreenAces_AboutInterest__1nEJX {

background-color: #23252C;
color: #F3BF99;

}


.AboutGreenAces_paragraphTwo__1fI6U {

  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12.24px;
  line-height: 150%;
  /* or 15px */
  color: #DCDCDC;
  background-color: #23252C;
  width: 68vw;
  height: 60vh;
}

.AboutGreenAces_paragraphTwoAlignment__1Zmri {

 text-align: left;
 background-color: #23252C;
 font-family: 'Inter';
 font-style: normal;
 font-weight: 400;
 font-size: 12.24px;
 line-height: 150%;
 /* or 15px */
 color: #DCDCDC;
 background-color: #23252C;
 width: 68vw;
 height: 60vh;



}


.AboutGreenAces_ParagraphTwoHighlights__PMFcq {

  text-align: left;
  background-color: #23252C;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 15.24px;
  
  /* or 15px */
  color: #DCDCDC;
  background-color: #23252C;
  width: 30vw;
  margin: 0 auto; /* add this line to center the element */
  
 

}



#AboutGreenAces_AB__3NcLm {


 background-color: #23252C;
 color: #F3BF99;
}

#AboutGreenAces_ABC__1HoPX {
 
 
 background-color: #23252C;
 color: #F3BF99;
}


#AboutGreenAces_ABCD__2MoIa {

 background-color: #23252C;
 color: #F3BF99;
}


#AboutGreenAces_ABCDE__3I-qL {

 background-color: #23252C;
 color: #F3BF99;
}


#AboutGreenAces_ABCDEF__h63fb {

 background-color: #23252C;
 color: #F3BF99;
}








/*body section*/








/*footer section*/

.AboutGreenAces_rectangle93__2aq5I {

  position: bottom;
  width: 68vw;
  height: 10vh;
  left: 0px;
  top: 0px;
  background: #161619;
  border-radius: 0px 0px 10px 10px;
  background-color: #23252C;



}



.AboutGreenAces_youtubeLogo__1z8_J {

  height: 25px;
  width: 25px;
  position: bottom;
  left: 18.82%;
  right: 56.64%;
  top: 89.8%;
  bottom: 7.32%;
  background: #161619;



}

.AboutGreenAces_tiktokLogo__1DSFJ {

  height: 25px;
  width: 25px;
  position: bottom;
  left: 47.73%;
  right: 47.73%;
  top: 89.8%;
  bottom: 7.32%;
  background: #161619;

}

.AboutGreenAces_twitterLogo__15RUc {

  height: 25px;
  width: 25px;
  position: bottom;
  left: 56.64%;
  right: 38.82%;
  top: 89.8%;
  bottom: 7.32%;
  background: #161619;

}

.AboutGreenAces_githubLogo__3C8Bl {

  height: 25px;
  width: 25px;
  position: bottom;
  left: 65.55%;
  right: 29.91%;
  top: 89.8%;
  bottom: 7.32%;
  background: #161619;

}





}





/* new code 02-14-2023*/

/* For desktop */
@media screen and (min-width: 768px) {
  .AboutGreenAces_smallBoarderBackground__1-dg8 {
    width: 50vw;
  }

  .AboutGreenAces_profileFrame__1Lllj {
    width: 50vw;
  }

  .AboutGreenAces_blackBorderTop__2Pxtu {
    width: 50vw;
  }

  .AboutGreenAces_blackBorder__1xnh- {
    width: 50vw;
  }

  .AboutGreenAces_insideBox__3uMla {
    width: 50vw;
    right: 25vw;
  }

  .AboutGreenAces_primarySection__3SvOs {
    width: 50vw;
  }

  .AboutGreenAces_aboutMeSection__2xoTm {
    width: 50vw;
  }

  .AboutGreenAces_paragraphOne__33RKk {
    width: 40vw;
  }

  .AboutGreenAces_paragraphTwo__1fI6U {
    width: 50vw;
  }



  .AboutGreenAces_footerSection__3LgB1 {
    width: 50vw;
  }

  .AboutGreenAces_rectangle93__2aq5I {
    width: 50vw;
  }
}

/* For mobile */
@media screen and (max-width: 768px) {

  .AboutGreenAces_frontendFont__3j6em{
    height:22vh;
  }
  .AboutGreenAces_smallBoarderBackground__1-dg8 {
    width: 80vw;
  }

  .AboutGreenAces_profileFrame__1Lllj {
    width: 80vw;
  }

  .AboutGreenAces_blackBorderTop__2Pxtu {
    width: 80vw;
  }

  .AboutGreenAces_blackBorder__1xnh- {
    width: 80vw;
  }

  .AboutGreenAces_insideBox__3uMla {
    width: 80vw;
    right: 10vw;
  }

  .AboutGreenAces_primarySection__3SvOs {
    width: 80vw;
  }

  .AboutGreenAces_aboutMeSection__2xoTm {
    width: 80vw;
  }

  .AboutGreenAces_paragraphOne__33RKk {
    text-align: center;
    width: 68vw;
  }

 

  .AboutGreenAces_paragraphTwo__1fI6U {
    text-align: center;
    width: 80vw;
  }


  .AboutGreenAces_ParagraphTwoHighlights__PMFcq {

    position: absolute;
    top: 283%;
    left: 47%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    width: 60vw;
  
  }

  .AboutGreenAces_footerSection__3LgB1 {
    width: 100%; 
    max-width: 1200px;
    margin: 0 auto;
  }

  .AboutGreenAces_rectangle93__2aq5I {
    width: 80vw;
  }
}





/*make font larger on larger screens*/


/*Settings for AboutGreenAces.js*/

/* For desktop: */
@media only screen and (min-width: 769px) {
  .AboutGreenAces_AboutPageBody__2dkvX {
    font-size: 16px;
  }

  .AboutGreenAces_greenacesText__O5BLw,
  .AboutGreenAces_frontendFont__3j6em,
  #AboutGreenAces_GreenAcesLink__1qpn5,
  .AboutGreenAces_paragraphOne__33RKk,
  #AboutGreenAces_paragraphOneAlignment__13o6n,
  .AboutGreenAces_AboutSectionContent__1hM7a,
  #AboutGreenAces_AboutHeadline__dMLZj,
  .AboutGreenAces_paragraphTwo__1fI6U,
  .AboutGreenAces_paragraphTwoAlignment__1Zmri,
  #AboutGreenAces_AB__3NcLm,
  #AboutGreenAces_ABC__1HoPX,
  #AboutGreenAces_ABCD__2MoIa,
  #AboutGreenAces_ABCDE__3I-qL,
  #AboutGreenAces_ABCDEF__h63fb {
    font-size: 20px;
  }
}

/* For mobile phones: */
@media only screen and (max-width: 768px) {
  .AboutGreenAces_AboutPageBody__2dkvX {
    box-sizing: border-box;
    background-color: #FFF;
  }

  /* other existing styles */
}

.ContactForm_spacer__3_jAR{
  margin-top: 20px;

}


.ContactForm_form-container__1I5F7 {
  background-color: #708090;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 85%; /* Change to desired width */
  margin: 0 auto; /* Add this line to center the form horizontally */
  border: 3px solid black; /* Add border to form container */
}

label {
  display: block;
  margin: 10px 0;
  font-weight: bold;
  color: black; /* Change label text color to black */
}

input[type="text"],
input[type="email"],
textarea {
  width: 100%; /* Change input width to 10% */
  background-color: #50e177; /* Change input background color to green */
  color: black; /* Change input text color to black */
  border: none;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

input[type="text"],
input[type="email"] {
  max-width: 100%;
}

input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  outline: none;
}

.ContactForm_form-container-textarea__2OE-q {
  background-color: #50e177;
  height: 100px;
  resize: none;
  
}

button {
  font-weight: bold;
  background-color: #50e177;
  color: black;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  margin: 10px auto;
  display: block;
  cursor: pointer;
  font-size: 1rem;
}

.ContactForm_responseColorMessage__3jRl1 {

  text-align: center;
  color: #50e177;

}


  /*start of viewport tag for mobile devices*/

  @viewport {
    width: device-width;
    zoom: 1.0;
    }
    

  /*for desktops or notebooks or larger screen sizes*/

  @media (min-width: 768px) {
    .ContactForm_form-container__1I5F7 {
      width: 85%; /* Adjust width for larger screens */
    }
  
    .ContactForm_form-container__1I5F7 label,
    .ContactForm_form-container__1I5F7 input,
    .ContactForm_form-container__1I5F7 textarea {
      width: 100%; /* Make inputs wider */
      max-width: 100%; /* Make inputs responsive */
    }
  
    .ContactForm_form-container__1I5F7 label {
      display: inline-block; /* Adjust label spacing */
      margin-right: 20px;
    }
  }
  

.Newsletter_GreenAces-Newsletter-Headline__1pkY8 {
    text-align: center;
}

.Newsletter_spacer__7kcOR{
    margin-top: 20px;
  
  }
  
  
  .Newsletter_form-container__18Hnn {
    background-color: #708090;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%; /* Change to desired width */
    margin: 0 auto; /* Add this line to center the form horizontally */
    border: 3px solid black; /* Add border to form container */
  }
  
  label {
    display: block;
    margin: 10px 0;
    font-weight: bold;
    color: black; /* Change label text color to black */
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%; /* Change input width to 10% */
    background-color: #50e177; /* Change input background color to green */
    color: black; /* Change input text color to black */
    border: none;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  
  input[type="text"],
  input[type="email"] {
    max-width: 100%;
  }
  
  input[type="text"]:focus,
  input[type="email"]:focus,
  textarea:focus {
    outline: none;
  }
  
  .Newsletter_form-container-textarea__1v11u {
    background-color: #50e177;
    height: 100px;
    resize: none;
    
  }
  
  button {
    font-weight: bold;
    background-color: #50e177;
    color: black;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    margin: 10px auto;
    display: block;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .Newsletter_responseColorMessage__2uy_B {
  
    text-align: center;
    color: #50e177;
  
  }
  
  
    /*start of viewport tag for mobile devices*/
  
    @viewport {
      width: device-width;
      zoom: 1.0;
      }
      
  
    /*for desktops or notebooks or larger screen sizes*/
  
    @media (min-width: 768px) {
      .Newsletter_form-container__18Hnn {
        width: 85%; /* Adjust width for larger screens */
      }
    
      .Newsletter_form-container__18Hnn label,
      .Newsletter_form-container__18Hnn input,
      .Newsletter_form-container__18Hnn textarea {
        width: 100%; /* Make inputs wider */
        max-width: 100%; /* Make inputs responsive */
      }
    
      .Newsletter_form-container__18Hnn label {
        display: inline-block; /* Adjust label spacing */
        margin-right: 20px;
      }
    }
    
  
form {
  max-width: 500px;
  margin: 0 auto;
  padding-bottom: 50px; /* adds space at the bottom to avoid interfering with the footer */
}

h1 {
  font-size: 28px;
  margin-bottom: 30px;
}

p {
  margin-top: 30px;
  margin-bottom: 15px;
  font-weight: bold;
}

p > label {
  font-weight: normal;
}

label {
  margin-left: 10px;
}

input[type="radio"] {
  margin-bottom: 15px;
  font-weight: normal;
}

textarea {
  margin-bottom: 15px;
  background-color: #4CAF50;
  color: black;
}

input[type="submit"] {
  display: block;
  margin: 0 auto;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #3e8e41;
  font-weight: bold;
  color: black;
}





/*start of viewport tag for mobile devices*/

@viewport {
  width: device-width;
  zoom: 1.0;
} 



/*start of viewport tag for mobile devices*/

@viewport {
    width: device-width;
    zoom: 1.0;
    }
    
    #Thanks_header__265nM {
    height: 30px;
    width: 100%;
    }


  #Thanks_content__3ySbe {
      background-color: #B0BEC5;
      height: 100%;
      width: 100%;
      margin-bottom: 30px;
    }

  #Thanks_footer__2eTUP {

      height: 30px;
      width: 100%;
      position: absolute;
      bottom: 0;


    }





/*end of viewport tag for mobile devices*/




/*content section*/

.Thanks_flex-wrapper__3sBX4 {
display: flex;
min-height: 100vh;
flex-direction: column;
justify-content: space-between;

}



/*images on index*/

#Thanks_ThankYouImage001__2THXD {

  width: 50%;
  display: block;
  margin: auto;
}


.Thanks_center__1_yhK {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}







.verifySubscription_h2-title__thsuO {
  text-align: center;
}

.verifySubscription_paragraph-message__1SCPH {

  text-align: center;
}

.verifySubscription_spacer__17QhW{
    margin-top: 20px;
  
  }
  
  
  .verifySubscription_form-container__9d02x {
    background-color: #708090;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%; /* Change to desired width */
    margin: 0 auto; /* Add this line to center the form horizontally */
    border: 3px solid black; /* Add border to form container */
  }
  
  label {
    display: block;
    margin: 10px 0;
    font-weight: bold;
    color: black; /* Change label text color to black */
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%; /* Change input width to 10% */
    background-color: #50e177; /* Change input background color to green */
    color: black; /* Change input text color to black */
    border: none;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  
  input[type="text"],
  input[type="email"] {
    max-width: 100%;
  }
  
  input[type="text"]:focus,
  input[type="email"]:focus,
  textarea:focus {
    outline: none;
  }
  
  .verifySubscription_form-container-textarea__10c5R {
    background-color: #50e177;
    height: 100px;
    resize: none;
    
  }
  
  button {
    font-weight: bold;
    background-color: #50e177;
    color: black;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    margin: 10px auto;
    display: block;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .verifySubscription_responseColorMessage__1DxEp {
  
    text-align: center;
    color: #50e177;
  
  }
  
  
    /*start of viewport tag for mobile devices*/
  
    @viewport {
      width: device-width;
      zoom: 1.0;
      }
      
  
    /*for desktops or notebooks or larger screen sizes*/
  
    @media (min-width: 768px) {
      .verifySubscription_form-container__9d02x {
        width: 85%; /* Adjust width for larger screens */
      }
    
      .verifySubscription_form-container__9d02x label,
      .verifySubscription_form-container__9d02x input,
      .verifySubscription_form-container__9d02x textarea {
        width: 100%; /* Make inputs wider */
        max-width: 100%; /* Make inputs responsive */
      }
    
      .verifySubscription_form-container__9d02x label {
        display: inline-block; /* Adjust label spacing */
        margin-right: 20px;
      }
    }
    
  
.Unsubscribe_h2-title__1Keeb {
  text-align: center;
}

.Unsubscribe_paragraph-message__3O_bb {

  text-align: center;
}


.Unsubscribe_spacer__2Sure{
    margin-top: 20px;
  
  }
  
  
  .Unsubscribe_form-container__P8zBJ {
    background-color: #708090;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 85%; /* /* Change to desired width */
    margin: 0 auto; /* Add this line to center the form horizontally */
    border: 3px solid black; /* Add border to form container */
  }
  
  label {
    display: block;
    margin: 10px 0;
    font-weight: bold;
    color: black; /* Change label text color to black */
  }
  
  input[type="text"],
  input[type="email"],
  textarea {
    width: 100%; /* Change input width to 10% */
    background-color: #50e177; /* Change input background color to green */
    color: black; /* Change input text color to black */
    border: none;
    border-radius: 5px;
    padding: 5px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }
  
  input[type="text"],
  input[type="email"] {
    max-width: 100%;
  }
  
  input[type="text"]:focus,
  input[type="email"]:focus,
  textarea:focus {
    outline: none;
  }
  
  .Unsubscribe_form-container-textarea__1k6DJ {
    background-color: #50e177;
    height: 100px;
    resize: none;
    
  }
  
  button {
    font-weight: bold;
    background-color: #50e177;
    color: black;
    padding: 5px 10px;
    border: none;
    border-radius: 5px;
    margin: 10px auto;
    display: block;
    cursor: pointer;
    font-size: 1rem;
  }
  
  .Unsubscribe_responseColorMessage__3LJR- {
  
    text-align: center;
    color: #50e177;
  
  }
  
  
    /*start of viewport tag for mobile devices*/
  
    @viewport {
      width: device-width;
      zoom: 1.0;
      }
      
  
    /*for desktops or notebooks or larger screen sizes*/
  
    @media (min-width: 768px) {
      .Unsubscribe_form-container__P8zBJ {
        
        width: 30%; /* Adjust width for larger screens */
      }
    
      .Unsubscribe_form-container__P8zBJ label,
      .Unsubscribe_form-container__P8zBJ input,
      .Unsubscribe_form-container__P8zBJ textarea {
        width: 125%; /* Make inputs wider */
        max-width: 125%; /* Make inputs responsive */
      }
    
      .Unsubscribe_form-container__P8zBJ label {
        display: inline-block; /* Adjust label spacing */
        margin-right: 20px;
      }
    }
    
  
.GreenAces404Page_h3__-fIc1 {
    text-align: center;
    font-family: 'Lucida Sans';
    color: black;
}




.GreenAces404Page_section__2gIBd {
    text-align: center;
    background-color: grey;
    height: 50vh;
    width: 100vw;
    position: center;
    height: -webkit-fill-available;

}



.GreenAces404Page_logo__9tCGa {
    height: 50vh;
    width: 50vw;
    position: center;
    text-align: center;
    height: -webkit-fill-available;
    
}

.GreenAces404Page_text__26cOZ {
    text-align: center;
    color: #50e177;
  

}




/*start of viewport tag for mobile devices*/

@viewport {
    width: device-width;
    zoom: 1.0;
    }


