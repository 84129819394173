/*Navbar settings for greenaces*/

.dropdown-menu{

    background-color: #50e177;
    font-family: Helvetica;
    font-weight: 300;
    
    
}

/*GreenAces logo here*/

#GreenAcesNavbarBrand {

    height: 50px;
    width: 75px;
}

        


#label-row-browser-games {
    background-color: #708090;
}

#label-row-Apps {
    background-color: #708090;
}

#label-row-Stuff {
    background-color: #708090;
}

.dropdown-menu :hover{

    background-color: #D3D3D3

}

#navbarDropdownProjects {
    color: #50e177;
}

#label-column-lucky {
    color: black;
    font-size: larger;
    
}

#label-column-pokemon {
    color: black;
    font-size: larger;
}



#navbarDropdownAndroid {
    color: #50e177;
}

#label-column-App1 {
    color: black;
    font-size: larger;
}

#label-column-App2 {
    color: black;
    font-size: larger;
}

#navbarDropdownOther {
    color: #50e177;
}

#label-column-Test1 {
    color: black;
    font-size: larger;

}

#label-column-Test2 {
    color: black;
    font-size: larger;

}

#label-column-Test3 {
    color: black;
    font-size: larger;

}




/*for mobile devices*/

    /*This is the hamburger icon that displays options */
  @media (max-width: 480px) {
    .navbar-toggler {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  