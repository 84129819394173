/* NOTE: This is the external css file for index2.html and it is controlled by lucky7.js (javascript file)
          created 9/27/2018*/


/*custom bootstrap 5 navbar settings*/


.navbarDropdownProjects {
color: #50e177;
background-color: #212529;
}

 .navbarDropdownAndroid {
color: #50e177;
 background-color: #212529;
}

 .navbarDropdownOther {
 color: #50e177;
 background-color: #212529;
 }

 html,
 body {
   height: 100%;
 }

 .blackWallImage {

  background-image: url('../assets/images/lucky7_game_images/Black-wallpapers-33.jpg');
  background-size: cover;
  min-height: 100%;

 }



 .formIconEdit {

  height: 35px;
  width:  35px;

}


.enjoyable_Yes_label,
.enjoyable_No_label,
.enjoyable_alright_label,
.age1_label,
.age2_label,
.age3_label,
.errors_Yes_label,
.errors_No_label,
.bugImpactGameplay_Yes_label,
.bugImpactGameplay_No_label,
.recommenedGame_Yes_label,
.recommenedGame_No_label,
.andriod_Yes_label,
.andriod_No_label,
.rateGame_1_label,
.rateGame_2_label,
.rateGame_3_label,
.rateGame_4_label,
.rateGame_5_label {
font-weight: normal;
}

               


/*table properties */


/*table 1 properties */
table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}
th, td {
  padding: 15px;
  text-align: left;
}

/*table 1 properties */

.t01 {

  width: 11%;
  background-color: #00FFFF;
  
}

.table-status-displayRounds {
  text-align: center;
  font-weight: bold;
}

.table-rounds-type {

  font-weight: bold;
}




.h1 {
  color: white;
  text-align: center;
  font-size: 20px
}

.p {
  font-family: times;
  font-size: 20px;
  color: white;
}

/*font settings for lucky 7 game */

.displayGameAlert {

color: red;
font-weight: bold;
}

.InformPlayerLoss {

color: white;
font-weight: bold;

}

.InformPlayerWon {

color: green;
font-weight: bold;
font-size: 22px

}

.InformPlayerWonDetails {
 color: white;
 font-weight: bold;
}


.sendLink a:hover, a:active {
  
  color: blue;
  text-decoration: underline;
  
}



.logo1 {

display: block;
margin-left: auto;
margin-right: auto;

width: 400px;
height: 71px;

}




.GameInfo {

text-align: left;
color: white;

}


.GameButtonAndText {

text-align: center;

}

.diceBoxOne {

  justify-content: center;
  align-content: center;
  grid-auto-flow: column;
  display: flex;
  position: relative;
  left: 40%;
  top: 40%;
  transform: translate(-20%, -9400%);
  max-height: 2px;
  max-width: 2px;

 

}

.diceBoxTwo {

  justify-content: center;
  align-content: center;
  grid-auto-flow: column;
  display: flex;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-500%, -9400%);
  max-height: 2px;
  max-width: 2px;
  
 

}




.GameNumberFont {

  display: block;
  margin-left: auto;
  margin-right: auto;



}

.displayRandomNumber {

color: green;
font-weight: bold;
text-align: center;
font-size: 90px

}



.PlayAgainButton img {

display: block;
margin: 0 auto;
width: 160px;
height: 77px;

}


.dice1 img {

display: block;
margin: 0 auto;
width: 95px;
height: 95px;

}

.dice2 img {

display: block;
margin: 0 auto;
width: 95px;
height: 95px;

}



  /*change viewport settings here*/

